
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import logo from "./logo.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import doit from "./logos/doit.png"
import flipside from "./logos/flipside.png"
import playmix from "./logos/playmix.png"
import quizr from "./logos/quizr.png"
import spellchecker from "./logos/spellchecker.png"
import taskmate from "./logos/taskmate.png"
import zippy from "./logos/zippy.png"
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

const messages = [
  {
    id: 1,
    subject: 'Onboarding Questionnaire',
    sender: 'Demo Ventures - Investment Team',
    time: '1d ago',
    preview:
      'The onboarding questionnaire is due soon. Get in touch if you have any issues or questions.',
  },
  {
    id: 1,
    subject: 'MVP Metrics',
    sender: 'Demo Ventures - Product Team',
    time: '2d ago',
    bg: 'red',
    preview:
      'The MVP Metrics task is now overdue. Please complete this task as a matter of urgency.',
  },
  {
    id: 2,
    subject: 'Welcome!',
    sender: 'Demo Ventures',
    time: '4d ago',
    preview:
      'Welcome to the Demo Ventures Company Portal! Please complete the assigned tasks.',
  },
]

const messages2 = [
  {
    id: 1,
    subject: 'The Demo Ventures Annual Meetup',
    sender: 'Annual Meetup',
    time: '1d ago',
    preview:
      'The DVAM will be held next Thursday at the Central Events Center. Come along to network with other founders & investors.',
  },
  {
    id: 1,
    subject: 'Community Wheels',
    sender: 'New Investment!',
    time: '4d ago',
    preview:
      'Community Wheels has just closed it\'s first funding round with Demo Ventures. The company is on a mission to make ride & vehicle sharing the future of travel',
  }
]

export default function AdminDashboard() {

  const navigate = useNavigate();

  return (<>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src={logo}
                alt=""
              />
              <div>
                <div className="flex items-center">

                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Dashboard
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">

          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5"></div>
        <Stats></Stats>

        <div className="grid grid-cols-2 gap-4">
          <div >
            <div className="border-b border-gray-200 pb-5 mt-5">
              <div onClick={() => navigate("/2/pcs")} className="float-right font-semibold text-neutral-500 text-md cursor-pointer underline">See All</div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Companies</h3>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-5">
              <PcCard img={cw} name="Community Wheels" industry="Transport" invested="50" own="Pre-Raise" />
              <PcCard img={buzzr} name="Buzzr" industry="Agriculture" invested="36" own="Pre-Raise" />
              <PcCard img={chatbubble} name="Chat Bubble" industry="Tech" invested="30" own="Own 14%" />
              <PcCard img={digical} name="Digical" industry="Tech" invested="100" own="Own 35%" />
            </div>
          </div>
          <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <div className="float-right font-semibold text-neutral-500 text-md cursor-pointer underline">See All</div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">Investors</h3>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-5">
              <InvestorCard ev="£325K" onClick={() => navigate("/2/investor")} img="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" name="Andrew Hall" industry="Angel" invested="87" />
              <InvestorCard ev="£392K" img="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" name="Evette Ramirez" industry="Angel" invested="120" />
              <InvestorCard ev="£415K" img="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" name="Shira Dixon" industry="Angel" invested="140" />
              <InvestorCard ev="£221K" img="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" name="Tom Ridinger" industry="Angel" invested="65" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </>
  )
}

const PcCard = ({ img, name, industry, invested, own, onClick }) => {
  return <div onClick={onClick} className="cursor-pointer rounded-md shadow-lg bg-white p-5 flex flex-col">
    <img src={img} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5" />
    <div className="text-neutral-800 text-xl font-semibold">{name}</div>
    <div className="text-neutral-500 text-sm mb-4">{industry}</div>
    <div className="flex justify-between">
      <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
      {own && <div className="text-neutral-700 font-semibold">{own}</div>}
    </div>
  </div>
}
const InvestorCard = ({ img, name, industry, invested, ev, onClick }) => {
  return <div onClick={onClick} className="cursor-pointer rounded-md shadow-lg bg-white flex">
    <img src={img} className="shadow h-40 object-cover rounded-l-md" />
    <div className="flex-1 p-5 my-auto">
      <div className="text-neutral-800 text-xl font-semibold">{name}</div>
      <div className="text-neutral-500 text-sm mb-4">{industry}</div>
      <div className="flex justify-between">
        <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
        <div className="text-neutral-700 font-semibold">Portfolio EV {ev}</div>
      </div>
    </div>

  </div>
}


const stats = [
  { name: 'Total No. of PCs', stat: '11', previousStat: '8', change: '37.5%', changeType: 'increase' },
  { name: 'Total Invested', stat: '£1.6 million', previousStat: '£1.2 million', change: '33.3%', changeType: 'increase' },
  { name: 'Total Estimated Value', stat: '£3.1 million', previousStat: '£2.2 million', change: '40.9%', changeType: 'increase' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Stats() {
  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Statistics (vs last quarter)</h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
