
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'

const positions = [
  {
    id: 2,
    title: 'Onboarding Questionnaire',
    type: 'Due Soon',
    tagColour: 'amber',
    location: 'General',
    department: '15-20 Minutes',
    closeDateFull: new Date(new Date().setDate(new Date().getDate() + 4)).toDateString(),
  },
  {
    id: 1,
    title: 'ESG Questionnaire',
    type: 'Not Started',
    tagColour: 'neutral',
    location: 'ESG',
    department: '1-2 Hours',
    closeDateFull: new Date(new Date().setDate(new Date().getDate() + 12)).toDateString(),
  },

  {
    id: 3,
    title: 'MVP Metrics',
    type: 'Late',
    tagColour: 'red',
    location: 'Product',
    department: '5 Minutes',
    closeDateFull: new Date(new Date().setDate(new Date().getDate() - 2)).toDateString(),
  },
]

const messages = [
  {
    id: 1,
    subject: 'Onboarding Questionnaire',
    sender: 'Demo Ventures - Investment Team',
    time: '1d ago',
    preview:
      'The onboarding questionnaire is due soon. Get in touch if you have any issues or questions.',
  },
  {
    id: 1,
    subject: 'MVP Metrics',
    sender: 'Demo Ventures - Product Team',
    time: '2d ago',
    bg: 'red',
    preview:
      'The MVP Metrics task is now overdue. Please complete this task as a matter of urgency.',
  },
  {
    id: 2,
    subject: 'Welcome!',
    sender: 'Demo Ventures',
    time: '4d ago',
    preview:
      'Welcome to the Demo Ventures Company Portal! Please complete the assigned tasks.',
  },
]

const messages2 = [
  {
    id: 1,
    subject: 'The Demo Ventures Annual Meetup',
    sender: 'Annual Meetup',
    time: '1d ago',
    preview:
      'The DVAM will be held next Thursday at the Central Events Center. Come along to network with other founders & investors.',
  },
  {
    id: 1,
    subject: 'Community Wheels',
    sender: 'New Investment!',
    time: '4d ago',
    preview:
      'Community Wheels has just closed it\'s first funding round with Demo Ventures. The company is on a mission to make ride & vehicle sharing the future of travel',
  }
]

export default function PcDashboard() {
  return (<>
    <div className="hidden bg-amber-100 text-amber-500 bg-red-100 text-red-500 bg-red-500/30"></div>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                alt=""
              />
              <div>
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                    alt=""
                  />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Good morning, Talia
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    123 City Road
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Demo Ventures PC
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Update Information
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Contact Demo Ventures
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Outstanding Tasks</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            These are the outstanding tasks set by Demo Ventures for you to complete. The data will be automatically submitted to Demo Ventures upon completion.
          </p>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mt-5">
          <ul role="list" className="divide-y divide-gray-200">
            {positions.map((position) => (
              <li key={position.id}>
                <a href="/0/task" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{position.title}</p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className={`inline-flex rounded-full bg-${position.tagColour}-100 px-2 text-xs font-semibold leading-5 text-${position.tagColour}-500`}>
                          {position.type}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">
                          <ClockIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          {position.department}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <TagIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          {position.location}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p>
                          Due {position.closeDateFull}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>


        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Messages</h3>
            </div>
            <div className="bg-white rounded-lg shadow overflow-hidden mt-5">
              <ul role="list" className="divide-y divide-gray-200">
              {messages.map((message) => (
                <li
                  key={message.id}
                  className={`relative bg-white bg-${message.bg}-500/30 py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50`}
                >
                  <div className="flex justify-between space-x-3">
                    <div className="min-w-0 flex-1">
                      <a href="#" className="block focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="truncate text-sm font-medium text-gray-900">{message.sender}</p>
                        <p className="truncate text-sm text-gray-500">{message.subject}</p>
                      </a>
                    </div>
                    <time className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                      {message.time}
                    </time>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-600 line-clamp-2">{message.preview}</p>
                  </div>
                </li>
              ))}
            </ul>
              </div>
            
          </div>
          <div>
            <div className="border-b border-gray-200 pb-5 mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-900">News</h3>
            </div>
            <div className="bg-white rounded-lg shadow overflow-hidden mt-5">
              <ul role="list" className="divide-y divide-gray-200  ">
              {messages2.map((message) => (
                <li
                  key={message.id}
                  className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
                >
                  <div className="flex justify-between space-x-3">
                    <div className="min-w-0 flex-1">
                      <a href="#" className="block focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="truncate text-sm font-medium text-gray-900">{message.sender}</p>
                        <p className="truncate text-sm text-gray-500">{message.subject}</p>
                      </a>
                    </div>
                    <time className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                      {message.time}
                    </time>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-600 line-clamp-2">{message.preview}</p>
                  </div>
                </li>
              ))}
            </ul>
              </div>
            
          </div>
        </div>

      </div>
    </div>



  </>

  )
}
