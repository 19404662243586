import { Routes, Route } from "react-router-dom";
import AdminDashboard from "./admin-dashboard";
import AdminInvestor from "./admin-investor";
import AdminLayout from "./admin-layout";
import AdminPCs from "./admin-pcs";
import './App.css';
import InvestorDashboard from "./investor-dashboard";
import InvestorLayout from "./investor-layout";
import InvestorPC from "./investor-pc";
import Login from "./login";
import Logout from "./logout";
import PcDashboard from "./pc-dashboard";
import PcLayout from "./pc-layout";
import PcTask from "./pc-task";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path='/0' element={<PcLayout />}>
        <Route path="/0" element={<PcDashboard></PcDashboard>} />
        <Route path="/0/task" element={<PcTask></PcTask>} />
      </Route>

      <Route path='/1' element={<InvestorLayout></InvestorLayout>}>
        <Route path="/1" element={<InvestorDashboard></InvestorDashboard>} />
        <Route path="/1/pc" element={<InvestorPC></InvestorPC>} />
      </Route>

      <Route path='/2' element={<AdminLayout></AdminLayout>}>
        <Route path="/2" element={<AdminDashboard></AdminDashboard>} />
        <Route path="/2/forms" element={<></>} />
        <Route path="/2/pcs" element={<AdminPCs></AdminPCs>} />
        <Route path="/2/investor" element={<AdminInvestor></AdminInvestor>} />
      </Route>
    </Routes>
  )
}
