
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import logo from "./logo.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import doit from "./logos/doit.png"
import flipside from "./logos/flipside.png"
import playmix from "./logos/playmix.png"
import quizr from "./logos/quizr.png"
import spellchecker from "./logos/spellchecker.png"
import taskmate from "./logos/taskmate.png"
import zippy from "./logos/zippy.png"
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

export default function AdminPCs() {
  return (<>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src={logo}
                alt=""
              />
              <div>
                <div className="flex items-center">

                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Portfolio Companies
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">

          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div >
          <div className="border-b border-gray-200 pb-5 mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Companies</h3>
          </div>
          <div className="grid grid-cols-4 gap-4 mt-5">
            <PcCard img={cw} name="Community Wheels" industry="Transport" invested="50" own="Pre-Raise" />
            <PcCard img={buzzr} name="Buzzr" industry="Agriculture" invested="36" own="Pre-Raise" />
            <PcCard img={chatbubble} name="Chat Bubble" industry="Tech" invested="30" own="Own 14%" />
            <PcCard img={digical} name="Digical" industry="Tech" invested="100" own="Own 35%" />

            <PcCard img={doit} name="DOIT" industry="Sustainability" invested="120" own="Own 25%" />
            <PcCard img={flipside} name="FlipSide" industry="Entertainment" invested="60" own="Own 20%" />
            <PcCard img={playmix} name="playmix" industry="Music" invested="30" own="Own 10%" />
            <PcCard img={quizr} name="Quizr" industry="Tech" invested="125" own="Own 30%" />
            <PcCard img={spellchecker} name="Spell Checker" industry="Tech" invested="10" own="Own 5%" />
            <PcCard img={taskmate} name="Task Mate" industry="Tech" invested="50" own="Own 10%" />
            <PcCard img={zippy} name="Zippy" industry="Tech" invested="60" own="Own 15%" />
          </div>
        </div>

      </div>
    </div>
  </>
  )
}

const PcCard = ({ img, name, industry, invested, own }) => {
  return <div className="rounded-md shadow-lg bg-white p-5 flex flex-col">
    <img src={img} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5" />
    <div className="text-neutral-800 text-xl font-semibold">{name}</div>
    <div className="text-neutral-500 text-sm mb-4">{industry}</div>
    <div className="flex justify-between">
      <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
      {own && <div className="text-neutral-700 font-semibold">{own}</div>}
    </div>
  </div>
}