import { Link } from "react-router-dom";

export default function Button(props) {

    const className = `${props.disabled ? 'opacity-20' : ''} cursor-pointer justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${props.className}`;

    if (props.link) {
        return <Link className={className} to={props.link}>{props.text}</Link>
    }
    return (
        <div className={className} onClick={() => props.disabled ? {} :  props.onClick()}>
            {props.text}
        </div>
    )
}