
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import doit from "./logos/doit.png"
import flipside from "./logos/flipside.png"
import playmix from "./logos/playmix.png"
import quizr from "./logos/quizr.png"
import spellchecker from "./logos/spellchecker.png"
import taskmate from "./logos/taskmate.png"
import zippy from "./logos/zippy.png"
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import { useNavigate } from 'react-router-dom'

const messages = [
  {
    id: 1,
    subject: 'Onboarding Questionnaire',
    sender: 'Demo Ventures - Investment Team',
    time: '1d ago',
    preview:
      'The onboarding questionnaire is due soon. Get in touch if you have any issues or questions.',
  },
  {
    id: 1,
    subject: 'MVP Metrics',
    sender: 'Demo Ventures - Product Team',
    time: '2d ago',
    bg: 'red',
    preview:
      'The MVP Metrics task is now overdue. Please complete this task as a matter of urgency.',
  },
  {
    id: 2,
    subject: 'Welcome!',
    sender: 'Demo Ventures',
    time: '4d ago',
    preview:
      'Welcome to the Demo Ventures Company Portal! Please complete the assigned tasks.',
  },
]

const messages2 = [
  {
    id: 1,
    subject: 'The Demo Ventures Annual Meetup',
    sender: 'Annual Meetup',
    time: '1d ago',
    preview:
      'The DVAM will be held next Thursday at the Central Events Center. Come along to network with other founders & investors.',
  },
  {
    id: 1,
    subject: 'Community Wheels',
    sender: 'New Investment!',
    time: '4d ago',
    preview:
      'Community Wheels has just closed it\'s first funding round with Demo Ventures. The company is on a mission to make ride & vehicle sharing the future of travel',
  }
]

export default function InvestorDashboard() {

  const navigate = useNavigate();

  return (<>
    <div className="hidden bg-amber-100 text-amber-500 bg-red-100 text-red-500 bg-red-500/30"></div>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <div>
                <div className="flex items-center">
                  <img
                    className="h-16 w-16 rounded-full sm:hidden"
                    src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Good morning, Andrew
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Demo Ventures Investor
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Update Information
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Contact Demo Ventures
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">
        <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Portfolio Companies</h3>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-5">
          <PcCard onClick={() => navigate("/1/pc")} img={cw} name="Community Wheels" industry="Transport" invested="25" own="Pre-Raise" />
          <PcCard onClick={() => navigate("/1/pc")} img={buzzr} name="Buzzr" industry="Agriculture" invested="12" own="Pre-Raise" />
          <PcCard onClick={() => navigate("/1/pc")} img={chatbubble} name="Chat Bubble" industry="Tech" invested="15" own="Own 7%" />
          <PcCard onClick={() => navigate("/1/pc")} img={digical} name="Digical" industry="Tech" invested="35" own="Own 10%" />
        </div>

        <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Performance</h3>
        </div>
        <div className="bg-white p-5 shadow rounded-md mt-5">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={[{ date: new Date(new Date().setDate(new Date().getDate() - 450)), invested: 35, ev: 35 },
            { date: new Date(new Date().setDate(new Date().getDate() - 360)), invested: 50, ev: 50 },
            { date: new Date(new Date().setDate(new Date().getDate() - 100)), invested: 50, ev: 120 },
            { date: new Date(new Date().setDate(new Date().getDate() - 90)), invested: 62, ev: 132 },
            { date: new Date(new Date().setDate(new Date().getDate() - 40)), invested: 62, ev: 210 },
            { date: new Date(new Date().setDate(new Date().getDate() - 10)), invested: 87, ev: 235 }]}>
              <Line name="Invested" type="stepAfter" dataKey="invested" stroke="#1d4ed8" />
              <Line name="EV" type="linear" dataKey="ev" stroke="#22c55e" />
              <XAxis dataKey="date"
                tickFormatter={(x) => new Date(x).toLocaleDateString()}
                type="category" />
              <YAxis label={{ value: "Value (£K)", angle: -90, dx: -25 }} />
              <CartesianGrid stroke="#f5f5f5" />
              <Legend />
            </LineChart>
          </ResponsiveContainer>
        </div>

      </div>
    </div>
  </>
  )
}

const PcCard = ({ img, name, industry, invested, own, onClick }) => {
  return <div onClick={onClick} className="cursor-pointer rounded-md shadow-lg bg-white p-5 flex flex-col">
    <img src={img} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5" />
    <div className="text-neutral-800 text-xl font-semibold">{name}</div>
    <div className="text-neutral-500 text-sm mb-4">{industry}</div>
    <div className="flex justify-between">
      <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
      <div className="text-neutral-700 font-semibold">{own}</div>
    </div>
  </div>
}
