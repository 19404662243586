
import { CalendarIcon, CheckCircleIcon, ClockIcon, DocumentIcon, MapPinIcon, PaperClipIcon, TagIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import buzzr from "./logos/buzzr.png"
import chatbubble from "./logos/chatbubble.png"
import digical from "./logos/digical.png"
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import Table from './components/table'
import { BuildingOfficeIcon, CreditCardIcon, UserIcon, UsersIcon } from '@heroicons/react/20/solid'
import { BoltIcon, ChartBarIcon, GlobeAltIcon, SunIcon, TableCellsIcon, CurrencyPoundIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react'


export default function AdminInvestor() {

  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)


  return (<>
    <Modal open={open} setOpen={setOpen}></Modal>
    <Modal2 open={open2} setOpen={setOpen2}></Modal2>
    <Modal3 open={open3} setOpen={setOpen3}></Modal3>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <div>
                <div className="flex items-center">
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Andrew Hall
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Verified Angel
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Message
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5 bg-white shadow rounded-lg p-5">
          <Tabs ti={tab} setTab={setTab}></Tabs>
          {tab === 0 && <div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">Andrew Hall</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Location</dt>
                  <dd className="mt-1 text-sm text-gray-900">London</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Invested</dt>
                  <dd className="mt-1 text-sm text-gray-900">£87k</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">First Investment Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">{new Date(new Date().setDate(new Date().getDate() - 450)).toDateString()}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Portfolio EV</dt>
                  <dd className="mt-1 text-sm text-gray-900">£325k ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    Andrew Hall is an angle investor interested in investing in innovated Tech based businesses across multiple sectors.
                  </dd>
                </div>
              </dl>
            </div>
          </div>}

          {tab === 1 && <div>
            <div className="grid grid-cols-4 gap-4">
              <PcCard onClick={() => setOpen2(true)} img={cw} name="Community Wheels" industry="Transport" invested="25" own="5" />
              <PcCard img={buzzr} name="Buzzr" industry="Agriculture" invested="12" own="2" />
              <PcCard img={chatbubble} name="Chat Bubble" industry="Tech" invested="15" own="7" />
              <PcCard img={digical} name="Digical" industry="Tech" invested="35" own="10" />
            </div>

            <button
              onClick={() => setOpen(true)}
              type="button"
              className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <CurrencyPoundIcon className="w-16 h-16 text-neutral-400 mx-auto"></CurrencyPoundIcon>
              <span className="mt-2 block text-sm font-semibold text-gray-900">Add Investment</span>
            </button>

          </div>}

          {tab === 2 && <>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500 mt-5">Community Wheels</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">Investment Report ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</span>
                    </div>
                    <div className="flex w-0 flex-1 items-center">
                      <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-400"></CheckIcon>
                      Updates automatically Sent (Quarterly)
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href="#" className="mr-5 font-medium text-blue-600 hover:text-blue-500">
                        View
                      </a>
                      <a href="#" className="font-medium text-red-600 hover:text-red-500">
                        Revoke
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1 items-center">
                      <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <span className="ml-2 w-0 flex-1 truncate">Due Diligence Report ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</span>
                    </div>

                    <div className="ml-4 flex-shrink-0">
                      <a href="#" className="mr-5 font-medium text-blue-600 hover:text-blue-500">
                        View
                      </a>
                      <a href="#" className="font-medium text-red-600 hover:text-red-500">
                        Revoke
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
            <button
              onClick={() => setOpen3(true)}
              type="button"
              className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <ChartBarIcon className="w-16 h-16 text-neutral-400 mx-auto"></ChartBarIcon>
              <span className="mt-2 block text-sm font-semibold text-gray-900">Send Report</span>
            </button>
          </>}
        </div>
      </div>
    </div >
  </>
  )
}

const tabs = [
  { name: 'Overview', href: '#', icon: GlobeAltIcon, current: false },
  { name: 'Investments', href: '#', icon: CurrencyPoundIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab }) {
  return (
    <div>

      <div className="hidden sm:block select-none">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

const PcCard = ({ img, name, industry, invested, own, onClick }) => {
  return <div onClick={onClick} className="cursor-pointer rounded-lg overflow-hidden shadow-lg bg-white flex flex-col">
    <div className="p-5">
      <img src={img} className="rounded-full shadow w-44 h-44 mx-auto object-cover mb-5" />
      <div className="text-neutral-800 text-xl font-semibold">{name}</div>
      <div className="text-neutral-500 text-sm mb-4">{industry}</div>
      <div className="flex justify-between">
        <div className="text-neutral-700 font-semibold">Invested £{invested}k</div>
        <div className="text-neutral-700 font-semibold">Own {own}%</div>
      </div>
    </div>

    <div className="hover:bg-blue-900 bg-blue-700 p-2 text-white text-center text-xl font-semibold">Manage</div>
  </div>
}


function Modal({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Investment
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="text-neutral-700 text-sm">Add a new investment to Andrew Hall's account</div>

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Company
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                        >
                          <option>Company...</option>

                          <option>DOIT</option>
                          <option>FlipSide</option>
                          <option>playmix</option>
                          <option>Quizr</option>
                          <option>Spell Checker</option>
                          <option>Task Mate</option>
                          <option>Zippy</option>
                        </select>
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="value"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Value
                        </label>
                        <input
                          type="number"
                          name="value"
                          id="value"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="£..."
                        />
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="equity"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Equity
                        </label>
                        <input
                          type="number"
                          name="equity"
                          id="equity"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="...%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Modal2({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Andrew Hall {'<>'} Community Wheels
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Company
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          disabled
                        >
                          <option>Community Wheels</option>
                        </select>
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="value"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Value
                        </label>
                        <input
                          type="number"
                          name="value"
                          id="value"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="£..."
                          value="25"
                        />
                      </div>

                      <div className="relative my-5">
                        <label
                          htmlFor="equity"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Equity
                        </label>
                        <input
                          type="number"
                          name="equity"
                          id="equity"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="...%"
                          value="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    onClick={() => setOpen(false)}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className="ml-5 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Modal3({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <ChartBarIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Send Report
                    </Dialog.Title>
                    <div className="mt-2">

                      <div className="text-neutral-700 text-sm">Send a report to Andrew Hall</div>

                      <div className="relative my-5">
                        <label
                          htmlFor="company"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                        >
                          Report
                        </label>
                        <select
                          name="company"
                          id="company"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                        >
                          <option>Report...</option>

                          <option>Buzzr ESG Report</option>
                          <option>Buzzr Product Report</option>
                          <option>Chat Bubble DEI Report</option>
                          <option>Digical Investment Report</option>
                        </select>
                      </div>


                      <div className="relative my-5 text-left">
                        <label
                          htmlFor="equity"
                          className="block relative bg-white px-1 text-xs font-medium text-gray-900 mb-3"
                        >
                          Automatically send updates
                        </label>
                        <Slider></Slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}





function Slider() {
  const [enabled, setEnabled] = useState(false)

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  )
}
