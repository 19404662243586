
import { CalendarIcon, CheckCircleIcon, ClockIcon, DocumentIcon, MapPinIcon, PaperClipIcon, TagIcon } from '@heroicons/react/20/solid'
import cw from "./logos/communitywheels.png"
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, ReferenceLine } from 'recharts';
import Table from './components/table'
import { BoltIcon, ChartBarIcon, GlobeAltIcon, SunIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import FileView from './components/file-view';

export default function InvestorPC() {

  const [tab, setTab] = useState(0)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)

  return (<>
    <Modal open={open} setOpen={setOpen}></Modal>
    <Modal2 open={open2} setOpen={setOpen2}></Modal2>
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <img
                className="hidden h-16 w-16 rounded-full sm:block"
                src={cw}
                alt=""
              />
              <div>
                <div className="flex items-center">
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                    Community Wheels
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                    <CheckCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                      aria-hidden="true"
                    />
                    Invested
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
            >
              Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="mt-5 bg-white shadow rounded-lg p-5">
          <Tabs ti={tab} setTab={setTab}></Tabs>
          {tab === 0 && <div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Company Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">Community Wheels</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Sector</dt>
                  <dd className="mt-1 text-sm text-gray-900">Transport</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Invested</dt>
                  <dd className="mt-1 text-sm text-gray-900">£25k</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Invested Date</dt>
                  <dd className="mt-1 text-sm text-gray-900">{new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">EV</dt>
                  <dd className="mt-1 text-sm text-gray-900">£25k ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">CEO</dt>
                  <dd className="mt-1 text-sm text-gray-900">Talia Ferns</dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    Community Wheels is a next generation ride and car sharing service. Our platform uses advanced AI to match you with the right car owner, or passenger, making a similar journey to you.
                  </dd>
                </div>
              </dl>
            </div>
          </div>}

          {tab === 1 && <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500 mt-5">Reports</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 w-0 flex-1 truncate">Investment Report ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a onClick={() => setOpen(true)} className="mr-5 font-medium text-blue-600 hover:text-blue-500">
                      View
                    </a>
                    <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                      Download
                    </a>
                  </div>
                </li>
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <span className="ml-2 w-0 flex-1 truncate">Due Diligence Report ({new Date(new Date().setDate(new Date().getDate() - 5)).toDateString()})</span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="#" className="mr-5 font-medium text-blue-600 hover:text-blue-500">
                      View
                    </a>
                    <a href="#" className="font-medium text-blue-600 hover:text-blue-500">
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>}

          {tab === 2 && <div className="sm:col-span-2">

            ESG

          </div>}

          {tab === 3 && <div className="sm:col-span-2">

            Risks

          </div>}

          {tab === 4 && <div className="sm:col-span-2">

            <Table onRowClick={() => setOpen2(true)} columns={[

              {
                Header: 'Metric',
                accessor: 'metric',
              },
              {
                Header: 'Value',
                accessor: 'value',
              },
              {
                Header: 'Trend',
                accessor: 'trend',
              },
              {
                Header: 'Date',
                accessor: 'date',
              },
            ]}

              data={[
                { metric: "Employees", value: "8", trend: <div className="flex"><Sparkline data={[2, 3, 3, 5, 7, 8]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
                { metric: "Annual Revenue", value: "£365k", trend: <div className="flex"><Sparkline data={[260, 365]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
                { metric: "MAU", value: "1200", trend: <div className="flex"><Sparkline data={[95, 450, 600, 650, 900, 1200]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
                { metric: "Senior Management Team Size", value: "4", trend: <div className="flex"><Sparkline data={[3, 2, 3, 3, 4, 4]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
                { metric: "No. of Female Senior Managers", value: "1", trend: <div className="flex"><Sparkline data={[1, 0, 0, 0, 1, 1]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
                { metric: "Female Senior Manager %", value: "25%", trend: <div className="flex"><Sparkline data={[33, 0, 0, 0, 25, 25]}></Sparkline></div>, date: new Date(new Date().setDate(new Date().getDate() - 5)).toDateString() },
              
              ]}></Table>

          </div>}

        </div>



      </div>
    </div >
  </>
  )
}

const tabs = [
  { name: 'Overview', href: '#', icon: GlobeAltIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
  { name: 'ESG', href: '#', icon: SunIcon, current: true },
  { name: 'Risks', href: '#', icon: BoltIcon, current: true },
  { name: 'Metrics', href: '#', icon: TableCellsIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Tabs({ ti, setTab }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block select-none">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, i) => (
              <a onClick={() => setTab(i)}
                key={tab.name}
                className={classNames(
                  i === ti
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
              >
                <tab.icon
                  className={classNames(
                    i === ti ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

function Modal({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <FileView></FileView>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


function Modal2({ open, setOpen }) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">


                <div>Employees</div>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={[{ date: new Date(new Date().setDate(new Date().getDate() - 420)), count: 2 },
                  { date: new Date(new Date().setDate(new Date().getDate() - 360)), count: 3 },
                  { date: new Date(new Date().setDate(new Date().getDate() - 270)), count: 3 },
                  { date: new Date(new Date().setDate(new Date().getDate() - 180)), count: 5 },
                  { date: new Date(new Date().setDate(new Date().getDate() - 90)), count: 7 },
                  { date: new Date(new Date().setDate(new Date().getDate() - 0)), count: 8 }]}>
                    <XAxis dataKey="date"
                      tickFormatter={(x) => new Date(x).toLocaleDateString()}
                      type="category" />
                    <YAxis label={{ value: "Number Of Employees", angle: -90, dx: -25 }} />
                    <CartesianGrid stroke="#f5f5f5" />
                    <ReferenceLine x={"12/28/2022"} stroke="green" label="Invested" />
                    <Line strokeWidth={3} type="stepAfter" dataKey="count" stroke="#1d4ed8" />

                  </LineChart>
                </ResponsiveContainer>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Sparkline({ data }) {

  const _data = data.map(x => { return { count: x } });


  return (
    <ResponsiveContainer width={70} height={20}>
      <LineChart width={70} height={20} data={_data}>
        <Line dot={false} dataKey="count" stroke="#22c55e" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  )
}