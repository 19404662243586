import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import report from '../reports/community-wheels-investment-report.pdf'

export default function FileView() {

  const [numPages, setNumPages] = useState(null);

  const params = useParams();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess} file={report} >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={800}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}

    </Document>
  )
}

